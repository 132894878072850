import validate from "/home/node/client/node_modules/nuxt/dist/pages/runtime/validate.js";
import enabled_45features_45global from "/home/node/client/middleware/enabled-features.global.ts";
import login_45short_45time_45token_45global from "/home/node/client/middleware/login-short-time-token.global.ts";
import trailing_45slash_45redirect_45global from "/home/node/client/middleware/trailing-slash-redirect.global.ts";
import manifest_45route_45rule from "/home/node/client/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  enabled_45features_45global,
  login_45short_45time_45token_45global,
  trailing_45slash_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/node/client/middleware/auth.ts"),
  login: () => import("/home/node/client/middleware/login.ts")
}